<template>
  <v-card>
    <v-container>
      <v-card-title>
        <span class="pl-1 primary--text">{{ formEditTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-form
          v-model="isFormValid"
          ref="form"
          form="form"
          id="form"
          @submit.prevent="saveEdit()"
        >
          <v-row>
            <v-col cols="12" class="pb-0 py-0 ma-0">
              <v-text-field
                v-model.trim="cajaNombre"
                class="mb-0 pb-0"
                label="Nombre"
                outlined
                clearable
                dense
                :rules="
                  rules.required.concat([
                    rules.maxLength(cajaNombre, 50),
                    rules.requiredTrim(cajaNombre)
                  ])
                "
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <v-autocomplete
               class="mb-0 mt-0 pt-0"
                outlined
                clearable
                dense
                multiple
                v-model="usersSelected"
                label="Usuarios"
                item-text="nombre"
                item-value="id"
                autocomplete="on"
                :items="usuarios"
                return-object
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="usuariosToggle()">
                    <v-list-item-action>
                      <v-icon
                        :color="usersSelected.length > 0 ? 'primary' : ''"
                      >
                        {{ multiselectIcon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Todos </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.nombre }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption">
                    (+{{ usersSelected.length - 1 }} otros)
                  </span>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="pt-0">
              <div id="simple-table-container">
                <v-simple-table dense height="205">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Usuarios asignados:</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="item in usersSelected" :key="item.id">
                        <td>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                          <p class="d-flex align-center my-0" v-on="on">
                            <v-checkbox
                              class=" py-0 mt-5"
                              
                              v-model="item.porDefecto"
                              small
                            > 
                          </v-checkbox
                            >{{ item.nombre }}
                          </p>
                          </template
                            >
                            <span>Usuario por defecto</span>
                          </v-tooltip>
                        </td>
                        <td>
                          <v-tooltip left>
                            <template v-slot:activator="{ on }">
                              <v-icon
                                small
                                v-on="on"
                                class="d-flex my-0"
                                @click="deleteUserSelected(item)"
                              >
                                {{ deleteIcon }}
                              </v-icon></template
                            >
                            <span>Borrar usuario</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </div>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn outlined @click="closeModalAndReload"> Cancelar </v-btn>
        <v-btn
          type="submit"
          :disabled="!isFormValid"
          form="form"
          color="primary"
        >
          Guardar
        </v-btn>
      </v-card-actions>
    </v-container>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/utils/helpers/rules";
import enums from "@/utils/enums/index.js";

export default {
  name: "EditCaja",
  props: ["cajaId"],
  data: () => ({
    isFormValid: false,
    deleteIcon: enums.icons.DELETE,
    checkIcon: enums.icons.CHECK_OUTLINE,
    mSCloseBoxIcon: enums.icons.CLOSE_BOX,
    checkBoxBlankOutlineIcon: enums.icons.CHECKBOX_BLANK_OUTLINE,
    formEditTitle: enums.titles.EDIT_CAJA,
    formNewTitle: enums.titles.NUEVA_CAJA,
    usersSelected: [],
    usuarios: [],
    rules: rules,
    cajaNombre: "",
  }),
  created() {
    if (this.cajaId != null) {
      this.setCaja(this.cajaId);
    } else {
      this.newCaja();
    }
    this.loadUsuarios();
  },
  computed: {
    multiselectIcon() {
      if (this.selectAllUsuarios) return this.mSCloseBoxIcon;
      return this.checkBoxBlankOutlineIcon;
    },
    selectAllUsuarios() {
      return (
        this.usersSelected && this.usersSelected.length === this.usuarios.length
      );
    },
  },
  methods: {
    ...mapActions({
      getUsuarios: "configuracion/getUsuariosCajeros",
      setAlert: "user/setAlert",
      getCajeros: "configuracion/getCajeros",
      getCajeroById: "configuracion/getCajeroById",
      postCajero: "configuracion/postCajero",
      deleteCajero: "configuracion/deleteCajero"
    }),
    closeModalAndReload() {
      this.$emit("closeAndReload");
    },
    async loadUsuarios() {
      const res1 = await this.getUsuarios();
      this.usuarios = res1;
    },
    async newCaja() {
      this.formEditTitle = this.formNewTitle;
    },

    async setCaja() {
      const response = await this.getCajeroById(this.cajaId);

      this.cajaNombre = response.cajaNombre;
      response.cajeros.forEach(element => {
        this.usersSelected.push(element.usuario);
      });
      this.usersSelected.sort(function(a, b) {
        if (a.nombre > b.nombre) {
          return 1;
        }
        if (a.nombre < b.nombre) {
          return -1;
        }
        // a must be equal to b
        return 0;
      });
    },


    async saveEdit() {
      let data = {};
      let userselected = this.usersSelected;
      let cajeroslist = [];

      userselected.forEach(element => {
        let varia = {
          usuario: element
        };
        cajeroslist.push(varia);
      });

      data = {
        cajaId: this.cajaId,
        cajaNombre: this.cajaNombre,
        cajeros: cajeroslist
      };

      const res = await this.postCajero(data);
      if (res.status === 200) {
        this.setAlert({ type: "success", message: "Guardado con éxito" });
        this.closeModalAndReload();
      }
    },

    deleteUserSelected(item) {
      this.usersSelected = this.usersSelected.filter(x => x.id !== item.id);
    },
    usuariosToggle() {
      this.$nextTick(() => {
        if (this.selectAllUsuarios) {
          this.usersSelected = [];
        } else {
          this.usersSelected = this.usuarios;
        }
      });
    }
  }
};
</script>

<style scoped></style>
