var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',[_c('span',{staticClass:"pl-1 primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveEdit()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"pb-0 py-0 ma-0",attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"mb-0 pb-0",attrs:{"label":"Nombre","outlined":"","clearable":"","dense":"","rules":_vm.rules.required.concat([
                  _vm.rules.maxLength(_vm.cajaNombre, 50),
                  _vm.rules.requiredTrim(_vm.cajaNombre)
                ])},model:{value:(_vm.cajaNombre),callback:function ($$v) {_vm.cajaNombre=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"cajaNombre"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"mb-0 mt-0 pt-0",attrs:{"outlined":"","clearable":"","dense":"","multiple":"","label":"Usuarios","item-text":"nombre","item-value":"id","autocomplete":"on","items":_vm.usuarios,"return-object":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.usuariosToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.usersSelected.length > 0 ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.multiselectIcon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
                var item = ref.item;
                var index = ref.index;
return [(index === 0)?_c('v-chip',[_c('span',[_vm._v(_vm._s(item.nombre))])]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.usersSelected.length - 1)+" otros) ")]):_vm._e()]}}]),model:{value:(_vm.usersSelected),callback:function ($$v) {_vm.usersSelected=$$v},expression:"usersSelected"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12"}},[_c('div',{attrs:{"id":"simple-table-container"}},[_c('v-simple-table',{attrs:{"dense":"","height":"205"},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Usuarios asignados:")])])]),_c('tbody',_vm._l((_vm.usersSelected),function(item){return _c('tr',{key:item.id},[_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('p',_vm._g({staticClass:"d-flex align-center my-0"},on),[_c('v-checkbox',{staticClass:" py-0 mt-5",attrs:{"small":""},model:{value:(item.porDefecto),callback:function ($$v) {_vm.$set(item, "porDefecto", $$v)},expression:"item.porDefecto"}}),_vm._v(_vm._s(item.nombre)+" ")],1)]}}],null,true)},[_c('span',[_vm._v("Usuario por defecto")])])],1),_c('td',[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"d-flex my-0",attrs:{"small":""},on:{"click":function($event){return _vm.deleteUserSelected(item)}}},on),[_vm._v(" "+_vm._s(_vm.deleteIcon)+" ")])]}}],null,true)},[_c('span',[_vm._v("Borrar usuario")])])],1)])}),0)]},proxy:true}])})],1)])],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModalAndReload}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid,"form":"form","color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }